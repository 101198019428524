// Base
// ===============

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 12px;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    background-color: #121212;
    color: #a0a0a0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #f1f1f1;
}

section {
    padding-top: 50px;
}

p {
    line-height: 1.4;
    margin-bottom: 12px;
}

img {
    width: 100%;
    max-width: 100%;
}

a {
    color: #eee;
    cursor: pointer;
}

// Helpers
// ===============

.font-5x {
    font-size: 4rem;

    @media (min-width: 768px) {
        font-size: 5rem;
    }
}

.section-title {
    font-size: 1.5rem;
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.ml-auto {
    margin-left: auto;
}

.btn {
    font-family: 'Montserrat', sans-serif;
    border: 0;
    outline: 0;
    padding: 6px 0;
    cursor: pointer;
    min-width: 150px;
}

.btn-accent {
    border: 0;
    font-weight: 700;
}

.container {
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 1240px) {
        max-width: 1200px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.col {
    width: 100%;
    padding-left: 20px;
}

.col-4 {
    @media (min-width: 768px) {
        width: 33.3333333%;
    }
}

.col-6 {
    @media (min-width: 768px) {
        width: 50%;
    }
}

.col-8 {
    @media (min-width: 768px) {
        width: 66.6666667%;
    }
}

.about {
    &__title {
        margin-bottom: 18px;
    }

    &__text p {
        font-size: 1.25rem;

        @media (min-width: 768px) {
            font-size: 1.5rem;
        }
    }

    &__img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__profile-pic {
        max-width: 300px;
        margin-top: 24px;

        @media (min-width: 768px) {
            margin-top: 0;
        }
    }
}

.work-item {
    background-color: #1a1a1a;
    margin-bottom: 20px;
    padding: 24px;

    &__desc-container {
        min-height: 185px;
        margin-bottom: 24px;

        @media (min-width: 768px) {
            min-height: 125px;
        }
    }

    &__title {
        font-size: 2rem;
        margin-bottom: 2px;
    }

    &__desc {
        font-size: 1.2rem;
    }

    &__links {
        display: flex;
    }

    &__link {
        text-decoration: none;
        padding: 6px 12px;
        border: 1px solid #eee;
        border-radius: 6px;
        background-color: #181818;
        cursor: pointer;
    }
}

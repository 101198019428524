.contact {
    &__items {
        padding-top: 50px;
    }

    &__item {
        display: inline-block;
        margin: 0 10px;

        svg {
            fill: #f1f1f1;
        }
    }

    &__svg-container {
        width: 30px;
    }
}

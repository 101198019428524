.work {
    &__title {
        font-size: 1.25rem;
        letter-spacing: 2px;
    }

    &__items {
        padding-top: 40px;
    }
}
